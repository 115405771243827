import { ApiConnector } from "./apiConnector";
import { ModalWindow } from "./modals";
import { SeerlinqDRIHeartCore } from "./quickLookAnalyses";
import {
  dateTimeISOString,
  dateTimeOrNull,
  parseDatetimeToLocal,
} from "./utils";
export const studiesToPhysicianConsent = [-1, 1, 4];
export async function canApprovePaperConsent(patient) {
  if (
    patient.informed_consent === 0 &&
    patient.patient_study.some((study) =>
      studiesToPhysicianConsent.includes(study)
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export async function canRevokePaperConsent(patient) {
  if (
    patient.informed_consent === 2 &&
    patient.patient_study.some((study) =>
      studiesToPhysicianConsent.includes(study)
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function isPaperConsent(patient) {
  if (
    patient.patient_study.some((study) =>
      studiesToPhysicianConsent.includes(study)
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function consentOk(patient) {
  const isPaper = isPaperConsent(patient);
  if (patient.informed_consent === 0) {
    return false;
  } else if (isPaper && patient.informed_consent === 2) {
    return true;
  } else if (!isPaper && patient.informed_consent === 1) {
    return true;
  } else {
    return false;
  }
}

const dayNames = {
  0: "Mon",
  1: "Tue",
  2: "Wed",
  3: "Thu",
  4: "Fri",
  5: "Sat",
  6: "Sun",
};

export class ListDataField {
  addList: any[] = [];
  removeList: any[] = [];
  constructor() {}

  async remove(item: any, removeFrom: any = null, index: number = null) {
    this.removeList.push(item);
    if (removeFrom != null) {
      removeFrom.splice(index, 1);
    }
  }

  async add(item: any, addTo: any = null) {
    this.addList.push(item);
    if (addTo != null) {
      addTo.push(item);
    }
  }

  async finalList(originalList: any[] = []) {
    const finalList = [...originalList];
    this.addList.forEach((item) => {
      if (!finalList.includes(item)) {
        finalList.push(item);
      }
    });
    this.removeList.forEach((item) => {
      const index = finalList.indexOf(item);
      if (index !== -1) {
        finalList.splice(index, 1);
      }
    });
    return finalList;
  }
}

class DataModel {
  htmlData: any[] = [];
  rawData: any[] = [];
  hasHistory: boolean = false;
  showingHistory: boolean = false;

  // sorting
  sortBy: string[] = [];
  initSort: boolean[] = [];
  sortByNames: string[] = [];
  canSwitchSortOrder: boolean = false;
  sortDesc: boolean[];

  // filtering
  filteringAttrs: any[] = [];
  filteringListAttrs: any[] = [];
  filteringIntAttrs: any[] = [];
  defaultFilter: object = null;
  fieldMapper: object = {};
  uniques: object = {};
  filter: object = {};

  queryFilter: string = "";
  queryKey: string = null;

  // field types
  stringFields: string[] = [];
  dateFields: string[] = [];
  dateTimeFields: string[] = [];

  // editing
  editable: string[] = [];

  // pagination
  rowsPerPage: number = 25;
  rowsPerPageOptions: number[] = [10, 25, 50, 100];
  currentPage: number = 1;
  totalPages: number = null;

  constructor(public data: any[], protected canShowHistory: boolean = false) {}

  async init() {
    if (this.hasHistory && this.canShowHistory) {
      await this.initHistory();
    }
    this.sortDesc = this.initSort.map((item) => item);

    // init filtering if necessary
    this.uniques = await this.filteringAttrs.reduce(
      async (objPromise, attr) => {
        const obj = await objPromise;
        const props = await this.getUniqueProperties(this.data, attr);
        obj[attr] = props;
        return obj;
      },
      Promise.resolve({})
    );
    for (const attr of this.filteringListAttrs) {
      const uniqueItems = new Set();
      this.data.forEach((item) => {
        if (Array.isArray(item[attr])) {
          item[attr].forEach((element) => {
            uniqueItems.add(element);
          });
        }
      });
      this.uniques[attr] = Array.from(uniqueItems);
    }
    this.filter = Object.fromEntries(
      Object.entries(this.uniques).filter(
        ([key, value]) => Array.isArray(value) && value.length > 0
      )
    );
    for (const attr of this.filteringIntAttrs) {
      this.filter[attr] = false;
    }
    if (this.defaultFilter != null) {
      for (const key in this.defaultFilter) {
        this.filter[key] = this.defaultFilter[key];
      }
    }
    this.htmlData = await this.sortFilterData(true);
    this.rawData = await this.sortFilterData(false);
    await this.calculateTotalPages();
  }

  async historyStr(item: any) {
    return `${await dateTimeOrNull(item.timestamp, true)} by ${item.username}`;
  }

  async initHistory() {
    this.data = await Promise.all(
      this.data.map(async (itemPromise) => {
        const item = await itemPromise;
        const lastUpdate =
          Array.isArray(item.update_history) && item.update_history.length > 0
            ? await this.historyStr(item.update_history[0])
            : null;

        return {
          ...item,
          ["lastUpdate"]: lastUpdate,
        };
      })
    );
  }

  async getUniqueProperties(list: object[], name: string) {
    return [...new Set(list.map((item) => item[name]))];
  }

  async calculateTotalPages() {
    this.totalPages = Math.ceil(this.htmlData.length / this.rowsPerPage);
    if (this.totalPages === 0) {
      this.totalPages = 1;
    }
  }

  async changeRowsPerPage() {
    await this.calculateTotalPages();
    this.currentPage = 1;
  }

  async nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  async prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  async resetPagination() {
    await this.calculateTotalPages();
    this.currentPage = 1;
  }

  async reload() {
    this.htmlData = await this.sortFilterData(true);
    this.rawData = await this.sortFilterData(false);
    await this.resetPagination();
  }

  get paginatedData() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = +start + +this.rowsPerPage;
    return this.htmlData.slice(start, end);
  }

  get rawPaginatedData() {
    const start = (this.currentPage - 1) * this.rowsPerPage;
    const end = +start + +this.rowsPerPage;
    return this.rawData.slice(start, end);
  }

  async switchSortOrder(newOrder: number[]) {
    if (this.canSwitchSortOrder) {
      if (newOrder.length !== this.sortBy.length) {
        throw new Error(
          "The length of newOrder must match the length of sortBy"
        );
      }
      this.sortBy = newOrder.map((index) => this.sortBy[index]);
      this.sortByNames = newOrder.map((index) => this.sortByNames[index]);
      this.initSort = newOrder.map((index) => this.initSort[index]);
      this.sortDesc = newOrder.map((index) => this.sortDesc[index]);

      await this.reload();
    }
  }

  async getSortNames() {
    return this.sortByNames.join(", ");
  }

  async rotateSortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      this.sortDesc[id] = true;
    } else if (this.sortDesc[id] === true) {
      this.sortDesc[id] = false;
    } else {
      if (this.sortBy.length === 1) {
        this.sortDesc[id] = true;
      } else {
        this.sortDesc[id] = null;
      }
    }
    await this.reload();
  }

  async resetSort() {
    this.sortDesc = this.initSort;
    await this.reload();
  }

  async sortState(name: string) {
    const id = this.sortBy.indexOf(name);
    if (this.sortDesc[id] === null) {
      return "&#9711;";
    } else if (this.sortDesc[id] === false) {
      return "&#9650;";
    } else {
      return "&#9660;";
    }
  }

  async sortAndFilterCustom(
    sortBy: string[],
    sortDesc: boolean[],
    filter: object
  ) {
    if (sortBy.length !== sortDesc.length) {
      throw new Error("The length of sortDir must match the length of sortBy");
    }
    const fieldSorter =
      (fields: string[], sortDesc: boolean[]) => (a: any, b: any) => {
        return fields
          .map((field: string, index: number) => {
            let dir = sortDesc[index] === null ? 0 : sortDesc[index] ? -1 : 1;

            if (this.dateFields.includes(field)) {
              const dateA = new Date(a[field]);
              const dateB = new Date(b[field]);
              return dateA > dateB ? dir : dateA < dateB ? -dir : 0;
            } else if (this.stringFields.includes(field)) {
              return (
                a[field].localeCompare(b[field], undefined, {
                  sensitivity: "base",
                }) * dir
              );
            } else {
              return a[field] > b[field] ? dir : a[field] < b[field] ? -dir : 0;
            }
          })
          .reduce((prev, next) => (prev ? prev : next), 0);
      };

    const sorted = this.data.sort(fieldSorter(sortBy, sortDesc));

    if (this.filteringAttrs.length > 0 || filter) {
      var filtered = sorted.filter((item) => {
        return Object.keys(filter).every((key) => {
          if (Array.isArray(item[key])) {
            return item[key].some((element) => filter[key].includes(element));
          } else {
            if (Array.isArray(filter[key])) {
              return filter[key].includes(item[key]);
            } else if (typeof filter[key] === "boolean") {
              return !(item[key] === 0 && filter[key]);
            }
          }
        });
      });
    } else {
      var filtered = sorted.map((item) => item);
    }

    return filtered;
  }

  async formatDataList(data: object[]) {
    return data.map((item) => {
      const newItem = { ...item };
      this.dateFields.forEach(async (field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = await dateTimeOrNull(value, false);
        }
      });
      this.dateTimeFields.forEach(async (field) => {
        if (newItem[field]) {
          const value = newItem[field];
          newItem[field] = await dateTimeOrNull(value, true);
        }
      });
      return newItem;
    });
  }

  async sortFilterData(format: boolean = true) {
    var filtered = await this.sortAndFilterCustom(
      this.sortBy,
      this.sortDesc,
      this.filter
    );
    if (this.queryKey && this.queryFilter !== "") {
      filtered = filtered.filter((item) => {
        const valueToCheck = String(item[this.queryKey]).toLowerCase();
        return valueToCheck.includes(this.queryFilter.toLowerCase());
      });
    }

    if (format) {
      return await this.formatDataList(filtered);
    } else {
      return filtered;
    }
  }

  async getEditedField(index: number) {
    const paginatedStart = (this.currentPage - 1) * this.rowsPerPage;
    const member = this.rawData[paginatedStart + index];
    var editedField = Object.keys(member)
      .filter(
        (key) => this.editable.length === 0 || this.editable.includes(key)
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    for (const key of this.dateTimeFields) {
      if (editedField.hasOwnProperty(key)) {
        const value = editedField[key];
        editedField[key] = value ? await parseDatetimeToLocal(value) : null;
      }
    }
    return editedField;
  }

  maybeInt(value: any) {
    const intValue = parseInt(value);
    return isNaN(intValue) ? value : intValue;
  }

  async handleFiltering(event, attr: string) {
    // find checkbox
    if (event.target.tagName === "LABEL") {
      var checkbox = event.target.querySelector('input[type="checkbox"]');
    } else if (event.target.tagName === "SPAN") {
      var checkbox = event.target
        .closest("label")
        .querySelector('input[type="checkbox"]');
    } else {
      var checkbox = event.target;
    }
    if (checkbox) {
      // if already selected -> reset
      if (
        this.filter[attr].length === 1 &&
        this.filter[attr][0] === this.maybeInt(checkbox.value)
      ) {
        this.filter[attr] = this.uniques[attr].map((item) =>
          this.maybeInt(item)
        );
      } else {
        // else select only this
        this.filter[attr] = [this.maybeInt(checkbox.value)];
      }
    }
  }

  async getLegend(
    thisName: string,
    switchFunction: string = "switchSortOrder([1, 0])"
  ) {
    const names = await this.getSortNames();
    let legend = "";
    if (this.filteringAttrs.length + this.filteringListAttrs.length > 0) {
      legend += `
        <p>
          <strong>Filtering: </strong>
          <span>Holding "shift" key will select <em>only</em> given item.</span><br />
          <span>Doing this again will reset filter and select all items.</span>
        </p>
      `;
    }

    if (this.defaultFilter != null) {
      legend += `<p><strong>Default filter: </strong><br />`;
      for (const key in this.defaultFilter) {
        const key_name = this.fieldMapper[key] ?? key;
        legend += `<strong>${key_name}:</strong> ${this.defaultFilter[key].join(
          ", "
        )}<br />`;
      }
      legend += `</p>`;
    }

    if (this.sortBy.length > 1) {
      if (this.canSwitchSortOrder) {
        var switchButton = `
          <button @click="${thisName}.${switchFunction}">Switch</button>
        `;
      } else {
        var switchButton = "";
      }
      legend += `
        <p>
          <strong>Sort order: </strong> ${names}
          ${switchButton}
        </p>
        <p>&#9660;: descending; &#9650;: ascending; &#9711;: do not sort</p>
        <p>
          <button @click="${thisName}.resetSort()">Reset sort</button>
        </p>
      `;
    }
    return legend;
  }

  async toggleHistory() {
    if (this.hasHistory && this.canShowHistory) {
      this.showingHistory = !this.showingHistory;
    }
  }

  async getRowsSelectorTags(thisName: string) {
    let rowsSelector = `
      <span>
        <label>Rows per page:</label>
        <select
          x-model="${thisName}.rowsPerPage"
          @change="${thisName}.changeRowsPerPage()"
        >
          <template x-for="option in ${thisName}.rowsPerPageOptions">
            <option
              x-text="option"
              :selected="option==${thisName}.rowsPerPage"
            ></option>
          </template>
        </select>
      </span>
    `;
    if (this.hasHistory && this.canShowHistory) {
      rowsSelector += `
        <span class='history'>
          <button
            @click="${thisName}.toggleHistory()"
          >Toggle history</button>
        </span>
      `;
    }
    return rowsSelector;
  }

  async getPaginationControlTags(thisName: string) {
    return `
      <button
        @click="${thisName}.prevPage()"
        :disabled="${thisName}.currentPage === 1"
      >
        &#9664;
      </button>
      <span
        >Page <span x-text="${thisName}.currentPage"></span> of
        <span x-text="${thisName}.totalPages"></span
      ></span>
      <button
        @click="${thisName}.nextPage()"
        :disabled="${thisName}.currentPage === ${thisName}.totalPages"
      >
        &#9654;
      </button>
    `;
  }

  async getIntFilteringTags(thisName: string, attr: string) {
    return `
      <button
        @click="${thisName}.filter['${attr}'] = !${thisName}.filter['${attr}']"
        class="dropdown-toggle"
        :class="{ 'passive': !${thisName}.filter['${attr}'] }"
        x-effect="await ${thisName}.reload();"
      >
        Only # > 0
      </button>
    `;
  }

  async getFilteringTags(
    thisName: string,
    attr: string,
    name: string,
    modelType: string = "",
    itemLookup: string = ""
  ) {
    if (itemLookup === "") {
      var itemName = "item";
    } else {
      var itemName = `${itemLookup}[item]`;
    }
    return `
      <div class="dropdown" x-data="{ open: false }">
        <button @click="open = !open" class="dropdown-toggle">
          Filter by ${name}
        </button>
        <div
          x-show="open"
          @click.away="open = false"
          class="dropdown-menu"
          x-effect="await ${thisName}.reload();"
        >
          <template x-for="item in ${thisName}.uniques['${attr}']">
            <label
              @click.shift.debounce.50ms="${thisName}.handleFiltering($event, '${attr}')"
            ><input
                type="checkbox"
                x-model${modelType}="${thisName}.filter['${attr}']"
                :disabled="${thisName}.uniques['${attr}'].length < 2"
                :value="item" />
              <span x-text="${itemName}"></span
            ></label>
          </template>
        </div>
      </div>
    `;
  }
}

class APIDataModel extends DataModel {
  paginated = false;
  apiTotalPages = null;
  apiTotalItems = null;
  apiPage = 1;
  firstPage = false;
  fullyLoaded = false;

  constructor(public api: ApiConnector, public pageSize: number = null) {
    super([]);
    if (pageSize) {
      this.rowsPerPage = pageSize;
    }
  }

  async calculateTotalPages() {
    if (!this.paginated || (this.paginated && this.fullyLoaded)) {
      await super.calculateTotalPages();
    } else {
      this.totalPages = this.apiTotalPages;
    }
  }
}

export class Patients extends APIDataModel {
  constructor(public api: ApiConnector, public pageSize: number) {
    super(api, pageSize);
    this.sortBy = ["patient_id", "created_at"];
    this.sortByNames = ["Patient HF study ID", "Added"];
    this.dateTimeFields = ["created_at", "last_ppg"];
    this.dateFields = ["date_of_birth"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = [
      "patient_state",
      "patient_status",
      "realm",
      "sex",
      "informed_consent",
    ];
    this.filteringListAttrs = ["patient_study"];
    this.filteringIntAttrs = ["alerts"];
    this.editable = ["patient_state", "monitoring_note"];
    this.queryKey = "patient_id";

    this.paginated = true;
  }

  // data initialization
  async dataInit(heartCoreInit: boolean = true) {
    this.data = this.data.map((item) => {
      const addedBy = item.user.username;
      return { ...item, ["added_by"]: addedBy };
    });
    if (this.api.amILevel3 && heartCoreInit) {
      await this.initHeartCore();
    }
    await super.init();
  }

  async initHeartCore() {
    this.data = this.data.map((item) => {
      const lastPPG = item.heart_core?.last_ppg ?? null;
      const canRunHC = item.heart_core?.can_run_heartcore ?? false;
      const patHeartCore = new SeerlinqDRIHeartCore(this.api);
      return {
        ...item,
        ["last_ppg"]: lastPPG,
        ["canRunHC"]: canRunHC,
        ["patHeartCore"]: patHeartCore,
      };
    });
  }

  // data fetching
  async init(heartCoreInit: boolean = true, loadAllPages: boolean = false) {
    await this.fetchFirstPage();
    await this.dataInit(heartCoreInit);
    this.firstPage = true;
    if (loadAllPages) {
      await this.fetchNextPage(heartCoreInit, true);
    }
  }

  async fetchFirstPage() {
    if (this.apiPage > 1) {
      return null;
    }
    const page = await this.api.getPatients(
      "basic",
      this.pageSize,
      this.apiPage
    );
    // set details
    this.apiTotalPages = page.pagination.total_pages;
    this.apiTotalItems = page.pagination.total_items;
    this.data = page.patients;
  }

  async fetchNextPage(heartCoreInit: boolean, recursive: boolean = false) {
    if (this.apiPage < this.apiTotalPages) {
      this.apiPage++;
      const nextPage = await this.api.getPatients(
        "basic",
        this.pageSize,
        this.apiPage
      );
      this.data = [...this.data, ...nextPage.patients];
      await this.dataInit(heartCoreInit);
    } else {
      this.fullyLoaded = true;
    }
    if (recursive && !this.fullyLoaded) {
      this.fetchNextPage(heartCoreInit, true);
    }
  }

  // other stuff
  async editRisk(patId: number, field: object) {
    await this.api.put(`patients/${patId}`, field);
    window.location.reload();
  }

  async editMonNote(patId: number, field: object) {
    await this.api.put(`patients/${patId}`, field);
    window.location.reload();
  }

  async canApprovePaperConsent(patient) {
    return await canApprovePaperConsent(patient);
  }

  async canRevokePaperConsent(patient) {
    return await canRevokePaperConsent(patient);
  }

  consentOk(patient) {
    return consentOk(patient);
  }

  disabledPatLink(patient) {
    return !this.consentOk(patient) && !this.api.amIAdmin;
  }
}

export class Users extends APIDataModel {
  userMapping = {};

  constructor(public api: ApiConnector) {
    super(api, null);
    this.sortBy = ["username", "created_at"];
    this.sortByNames = ["Username", "Added"];
    this.dateTimeFields = ["created_at", "last_login"];
    this.stringFields = ["username"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["role", "active", "monitoring_team", "added_by"];
    this.editable = [
      "connected_patient_id",
      "preferred_language",
      "managed_patients",
    ];
    this.defaultFilter = {
      role: ["admin", "seerlinq-user", "study-physician", "physician"],
    };
    this.queryKey = "username";

    this.paginated = false;
  }

  async dataInit() {
    this.data = this.data.map((item) => {
      if (item.monitoring_team != null) {
        var monTeam = item.monitoring_team.display_name;
      } else {
        var monTeam = null;
      }
      return { ...item, ["monitoring_team"]: monTeam };
    });
    await super.init();
    await this.getMapping();
  }

  async init() {
    const users = await this.api.get("users");
    this.data = users.users;
    await this.dataInit();
    this.firstPage = true;
    this.fullyLoaded = true;
  }

  async initPhysiciansOnly() {
    const users = await this.api.get("users/physicians");
    this.data = users.users;
    await this.dataInit();
    this.firstPage = true;
    this.fullyLoaded = true;
  }

  async getMapping() {
    for (const user of this.data) {
      this.userMapping[user.uuid] = user.username;
    }
  }

  async generateLinks(ids: number[]) {
    return ids
      .map(
        (id) =>
          `<strong><a style="color: #bb16a3" href="#/patient/${id}">${id}</a></strong>`
      )
      .join(", ");
  }
}

export class Diagnoses extends DataModel {
  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["diagnosed_at", "diagnosis_name"];
    this.sortByNames = ["Diagnosed", "Name"];
    this.dateFields = ["diagnosed_at", "remission"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["diagnosis_name"];
    this.editable = [
      "diagnosed_at",
      "diagnosis_value",
      "diagnosis_confidence",
      "remission",
      "comment",
    ];
    this.initSort = [true, null];
  }
}

export class DerivedData extends DataModel {
  // constants
  algoFlags = {
    0: "Wrong",
    1: "OK",
    2: "Low PPG Quality",
  };
  dataGroups = { hr: "HR", hrv: "HR Variability", spo2: "SpO2" };
  aggTypes = { full: "full", window: "windowed" };
  aggFuncs = {
    mean: "mean",
    median: "median",
    std: "STD",
    min: "min",
    max: "max",
    sdnn: "SDNN",
    sdsd: "SDSD",
    rmssd: "RMSSD",
    pnn50: "pNN50",
    pnn20: "pNN20",
    psd_lf: "low frequency power",
    psd_hf: "high frequency power",
    sample_entropy: "entropy",
  };
  spo2Name = "SpO2: mean";
  hrName = "HR: mean";

  constructor(data) {
    super(data);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.initSort = [true];
    this.filteringAttrs = [
      "data_group",
      "rolling",
      "aggregation_function",
      "measurement_type",
      "seerlinq_measurement_quality_flag",
    ];
    this.filteringListAttrs = ["tags"];
    this.defaultFilter = {
      rolling: ["full"],
      aggregation_function: [
        "mean",
        "sdnn",
        "sdsd",
        "pnn50",
        "psd_lf",
        "psd_hf",
        "sample_entropy",
      ],
    };
    this.fieldMapper = { rolling: "Aggregation type" };
  }

  async strWindow(window) {
    if (window == null) {
      return "";
    } else {
      const windowStr = window.window_function + ": " + window.length + "s / ";
      if (window.length === window.step) {
        return windowStr + "non-overlapping";
      } else {
        return windowStr + "s step";
      }
    }
  }

  async filterSpO2() {
    this.data = this.data.filter((item) => {
      return (
        item.measurement_type == this.spo2Name &&
        item.data_group == "spo2" &&
        item.rolling == "full"
      );
    });
  }

  async filterHR() {
    this.data = this.data.filter((item) => {
      return (
        item.measurement_type == this.hrName &&
        item.data_group == "hr" &&
        item.rolling == "full"
      );
    });
  }
}

export class PPGs extends DataModel {
  toQuickLook = [];

  constructor(data) {
    super(data);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.editable = [
      "measurement_condition",
      "measurement_mode",
      "quality_flag",
      "comment",
    ];
    this.initSort = [true];
    this.filteringAttrs = [
      "measurement_condition",
      "measurement_device",
      "measurement_origin",
      "measurement_mode",
      "quality_flag",
    ];
  }

  async toggleQuickLook(uuid) {
    if (this.toQuickLook.includes(uuid)) {
      this.toQuickLook = this.toQuickLook.filter((p) => p != uuid);
    } else {
      this.toQuickLook.push(uuid);
    }
  }

  async selectAllQuickLook() {
    const filtered = await this.sortFilterData();
    this.toQuickLook = filtered.map((obj) => obj.uuid);
  }

  async clearQuickLook() {
    this.toQuickLook = [];
  }
}

export class Computed extends DataModel {
  // constants
  algoFlags = {
    0: "Wrong",
    1: "OK",
    2: "Low PPG Quality",
  };
  driName = "diastolic reserve index";

  constructor(data) {
    super(data);
    this.sortBy = ["measurement_datetime"];
    this.sortByNames = ["Measured"];
    this.dateTimeFields = ["measurement_datetime"];
    this.editable = ["seerlinq_measurement_quality_flag", "comment"];
    this.initSort = [true];
    this.filteringAttrs = [
      "measurement_type",
      "seerlinq_algorithm",
      "seerlinq_algorithm_version",
      "seerlinq_measurement_quality_flag",
    ];
    this.filteringListAttrs = ["tags"];
  }

  async init() {
    this.data = this.data.map((item) => {
      const ppgConditions = item["ppg_uuids"].map((ppg) => {
        return ppg["measurement_condition"];
      });
      return {
        ...item,
        ["ppg_conditions"]: ppgConditions.sort((a, b) => a.localeCompare(b)),
      };
    });
    await super.init();
  }

  async filterDRIOnly() {
    this.data = this.data.filter((item) => {
      return item.measurement_type == this.driName;
    });
  }
}

export class MedData extends DataModel {
  // constants
  notEditable = ["ePVS", "CKD-EPI", "CHA2DS2-VA", "BMI", "congestion score"];

  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["measurement_datetime", "measurement_type"];
    this.sortByNames = ["Measured", "Type"];
    this.dateTimeFields = ["measurement_datetime", "created_at"];
    this.stringFields = ["measurement_type"];
    this.editable = ["measurement_datetime", "measurement_value", "comment"];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;
  }
}

export class MergedMedData extends MedData {
  originFieldName = "data_source";

  medDataKeys = [
    "measurement_datetime",
    "measurement_type",
    "measurement_value",
    "measurement_unit",
    "comment",
    "created_at",
    "update_history",
    "uuid",
  ];
  flagKey = "seerlinq_measurement_quality_flag";
  algoFlags = {
    0: "Wrong",
    1: "OK",
    2: "Low PPG Quality",
  };

  constructor(medData, canShowHistory = false) {
    super(medData, canShowHistory);
    this.filteringAttrs = [this.originFieldName];
  }

  async mergeWithPPGDerived(derivedDataList, keyToRename, valueMapping) {
    const filterAndLabel = (list, label, renameKey = true, defaultQF = null) =>
      list.map((item) => {
        const filteredItem = this.medDataKeys.reduce((acc, key) => {
          if (item.hasOwnProperty(key)) {
            acc[key] =
              renameKey &&
              key === keyToRename &&
              valueMapping[item[key]] !== undefined
                ? valueMapping[item[key]]
                : item[key];
          }
          return acc;
        }, {});
        filteredItem[this.originFieldName] = label;
        if (defaultQF !== null) {
          filteredItem[this.flagKey] = defaultQF;
        }

        return filteredItem;
      });
    const medData = filterAndLabel(
      this.data,
      "Patient / Physician",
      false,
      "N/A"
    );
    const derivedData = derivedDataList
      .map((item) => filterAndLabel(item.data, "PPG-derived", true))
      .flat();
    this.data = [...medData, ...derivedData];
  }

  flag(member) {
    return this.algoFlags[member[this.flagKey]] || "N/A";
  }
}

export class Symptoms extends DataModel {
  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["symptom_date", "symptom_name"];
    this.sortByNames = ["Started", "Symptom"];
    this.dateFields = ["symptom_date", "symptom_change_date"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["symptom_name"];
    this.editable = [
      "symptom_date",
      "symptom_name",
      "symptom_value",
      "symptom_change_in_last_six_m",
      "symptom_change_date",
      "symptom_value_before",
      "comment",
    ];
    this.initSort = [true, null];
    this.canSwitchSortOrder = true;
  }
}

export class Events extends DataModel {
  // constants
  eventTypes = {
    patient_contact: "Patient contact",
    medical_call: "Medical call",
    medication_change: "Medication change",
    check_up: "Check-up",
    labs: "Labs",
    hospitalization: "Hospitalization",
    patient_non_adherence: "Patient non-adherence",
    physician_notified: "Treating physician notified",
  };

  constructor(data) {
    super(data);
    this.sortBy = ["event_date", "event_timestamp"];
    this.sortByNames = ["Date", "Timestamp"];
    this.dateTimeFields = ["event_timestamp"];
    this.dateFields = ["event_date"];
    this.stringFields = ["event_description", "event_comment"];
    this.editable = ["event_description", "event_comment"];
    this.initSort = [true, null];
    this.filteringAttrs = ["event_type"];
    this.canSwitchSortOrder = true;
  }
}

export class Thresholds extends DataModel {
  // constants
  threshTables = {
    medicaldata: "Medical data (Manual)",
    symptoms: "Symptoms (Manual)",
    ppg_derived: "PPG-derived (from Oximeter)",
    sq_computed: "SQ-computed (our algorithm)",
  };
  // defaults
  defaultThresholds = [
    {
      threshold_variable: "diastolic reserve index (median)",
      threshold_variable_table: "sq_computed",
      threshold_type: "low",
      threshold_value: 80.0,
      isDefault: true,
    },
    {
      threshold_variable: "blood pressure systolic",
      threshold_variable_table: "medicaldata",
      threshold_type: "low",
      threshold_value: 90.0,
      isDefault: true,
    },
    {
      threshold_variable: "blood pressure systolic",
      threshold_variable_table: "medicaldata",
      threshold_type: "high",
      threshold_value: 140.0,
      isDefault: true,
    },
    {
      threshold_variable: "blood pressure diastolic",
      threshold_variable_table: "medicaldata",
      threshold_type: "low",
      threshold_value: 40.0,
      isDefault: true,
    },
    {
      threshold_variable: "blood pressure diastolic",
      threshold_variable_table: "medicaldata",
      threshold_type: "high",
      threshold_value: 90.0,
      isDefault: true,
    },
    {
      threshold_variable: "heart rate",
      threshold_variable_table: "medicaldata",
      threshold_type: "low",
      threshold_value: 50.0,
      isDefault: true,
    },
    {
      threshold_variable: "heart rate",
      threshold_variable_table: "medicaldata",
      threshold_type: "high",
      threshold_value: 100.0,
      isDefault: true,
    },

    {
      threshold_variable: "fatigue score",
      threshold_variable_table: "symptoms",
      threshold_type: "high",
      threshold_value: 7,
      isDefault: true,
    },
    {
      threshold_variable: "shortness of breath",
      threshold_variable_table: "symptoms",
      threshold_type: "high",
      threshold_value: 3,
      isDefault: true,
    },
    {
      threshold_variable: "fatigue score: increase",
      threshold_variable_table: "symptoms",
      threshold_type: "high",
      threshold_value: 4,
      threshold_comment: "2 days look-back, max. 3 values",
      isDefault: true,
    },
    {
      threshold_variable: "shortness of breath: increase",
      threshold_variable_table: "symptoms",
      threshold_type: "high",
      threshold_value: 2,
      threshold_comment: "2 days look-back, max. 3 values",
      isDefault: true,
    },
    {
      threshold_variable: "SpO2: mean",
      threshold_variable_table: "ppg_derived",
      threshold_type: "low",
      threshold_value: 94.0,
      isDefault: true,
    },
    {
      threshold_variable: "SpO2: mean: decrease",
      threshold_variable_table: "ppg_derived",
      threshold_type: "low",
      threshold_value: 5,
      threshold_comment: "2 days look-back, max. 6 values",
      isDefault: true,
    },
    {
      threshold_variable: "HR: mean",
      threshold_variable_table: "ppg_derived",
      threshold_type: "low",
      threshold_value: 50.0,
      isDefault: true,
    },
    {
      threshold_variable: "HR: mean",
      threshold_variable_table: "ppg_derived",
      threshold_type: "high",
      threshold_value: 100.0,
      isDefault: true,
    },
  ];

  constructor(data) {
    super(data);
    this.sortBy = ["isDefault", "threshold_variable"];
    this.sortByNames = ["Variable"];
    this.stringFields = ["threshold_variable"];
    this.filteringAttrs = ["threshold_type", "threshold_variable_table"];
    this.editable = ["threshold_value", "threshold_comment"];
    this.initSort = [false, false];
  }

  async init() {
    this.data = this.data.map((item) => {
      return { ...item, ["isDefault"]: false };
    });
    const existingThreshs = new Set(
      this.data.map(
        (item) => `${item["threshold_variable"]}|${item["threshold_type"]}`
      )
    );
    const defaultsFilt = this.defaultThresholds.filter(
      (item) =>
        !existingThreshs.has(
          `${item["threshold_variable"]}|${item["threshold_type"]}`
        )
    );
    this.data = this.data.concat(defaultsFilt);
    await super.init();
  }
}

export class Clinics extends DataModel {
  constructor(data) {
    super(data);
    this.sortBy = ["name"];
    this.sortByNames = ["Name"];
    this.initSort = [false];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm", "country"];
    this.filteringIntAttrs = ["numPhysicians"];
    this.editable = [
      "name",
      "ambulance",
      "address",
      "country",
      "realm",
      "phone",
      "email",
      "physicians",
    ];

    this.rowsPerPage = 10;
  }

  async init() {
    this.data = this.data.map((item) => {
      const numPhysicians = item.physicians.length;
      return { ...item, ["numPhysicians"]: numPhysicians };
    });
    await super.init();
  }
}

export class Physicians extends DataModel {
  constructor(data) {
    super(data);
    this.sortBy = ["surname"];
    this.sortByNames = ["Name"];
    this.initSort = [true];
    this.dateTimeFields = ["created_at"];
    this.canSwitchSortOrder = false;
    this.filteringAttrs = ["realm"];
    this.editable = [
      "surname",
      "given_name",
      "titles",
      "realm",
      "email",
      "clinics",
      "patients",
      "connected_user",
      "phone",
    ];

    this.rowsPerPage = 10;
  }

  async init() {
    this.data = this.data.map((item) => {
      const numClinics = item.clinics.length;
      const clinics = item.clinics
        .map((obj) => `${obj["name"]} (${obj["ambulance"]})`)
        .join(" \\ ");
      const numPatients = item.patients.length;
      const username = item.connected_user?.username ?? null;
      return {
        ...item,
        ["numClinics"]: numClinics,
        ["clinicsStr"]: clinics,
        ["numPatients"]: numPatients,
        ["username"]: username,
      };
    });
    await super.init();
  }
}

export class Alerts extends DataModel {
  // constants
  alertTypes = {
    "-3": "CRITICAL low",
    "-2": "Alert low",
    "-1": "warning low",
    0: "normal",
    1: "warning high",
    2: "Alert high",
    3: "CRITICAL high",
  };

  constructor(data) {
    super(data);
    this.sortBy = ["patient_id", "alert_timestamp"];
    this.sortByNames = ["Patient", "Timestamp"];
    this.dateTimeFields = ["alert_timestamp", "created_at"];
    this.initSort = [null, true];
    this.canSwitchSortOrder = true;
    this.filteringAttrs = ["alert_variable", "alert_type", "alert_seen"];
    this.filteringListAttrs = ["alert_tags"];
  }
}

export class Schedules extends DataModel {
  constructor(data) {
    super(data);
    this.dateFields = ["start_date"];
  }

  async schedDays(member) {
    return await member.schedule_frequency_on_days
      .map((day) => dayNames[day])
      .join("/");
  }

  async schedString(member) {
    const ordinalSuperscript = { 1: "st", 2: "nd", 3: "rd" };
    if (member.schedule_frequency_regular_repeat_every != null) {
      const ordinalStr =
        ordinalSuperscript[
          parseInt(member.schedule_frequency_regular_repeat_every)
        ] || "th";
      var freqString =
        "Every " +
        member.schedule_frequency_regular_repeat_every +
        ordinalStr +
        " day";
    } else {
      var freqString = "Days: " + (await this.schedDays(member));
    }
    freqString += " at times: " + member.schedule_times.join(", ");
    return freqString;
  }
}

export class Medications extends DataModel {
  switched = false;
  switchedOrder = [1, 2, 0];
  origOrder = [2, 0, 1];

  // constants
  changeDosageEditable = [
    "medication_dose",
    "medication_unit",
    "medication_dosage",
  ];
  changeId = null;
  changeDosageField: any = {
    medication_dosage: [],
    medication_change_date: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
  };

  constructor(data, canShowHistory = false) {
    super(data, canShowHistory);
    this.hasHistory = true;
    this.sortBy = ["medication_started", "medication_ended", "medication_name"];
    this.sortByNames = ["Started", "Ended", "Name"];
    this.dateFields = ["medication_started", "medication_ended"];
    this.dateTimeFields = ["created_at"];
    this.stringFields = ["medication_name"];
    this.editable = [
      "medication_started",
      "medication_ended",
      "medication_dose",
      "medication_unit",
      "medication_dosage",
      "comment",
    ];
    this.filteringAttrs = ["medication_group"];
    this.initSort = [true, null, null];
    this.canSwitchSortOrder = true;
  }

  async canEditName(member) {
    if (member.medication_group === "Other") {
      return true;
    } else {
      return false;
    }
  }

  async freqDays(member) {
    return await member.medication_frequency_on_days
      .map((day) => dayNames[day])
      .join("/");
  }

  async freqString(member) {
    const ordinalSuperscript = { 1: "st", 2: "nd", 3: "rd" };
    if (member.medication_frequency_regular_repeat_every != null) {
      const ordinalStr =
        ordinalSuperscript[
          parseInt(member.medication_frequency_regular_repeat_every)
        ] || "th";
      return (
        "Every " +
        member.medication_frequency_regular_repeat_every +
        ordinalStr +
        " day"
      );
    } else {
      return "Days: " + (await this.freqDays(member));
    }
  }

  async customSwitch() {
    if (this.switched) {
      await this.switchSortOrder(this.switchedOrder);
    } else {
      await this.switchSortOrder(this.origOrder);
    }
    this.switched = !this.switched;
  }

  async getDosageChangeField(index) {
    const member = this.rawData[index];
    var editedField = Object.keys(member)
      .filter(
        (key) =>
          this.changeDosageEditable.length === 0 ||
          this.changeDosageEditable.includes(key)
      )
      .reduce((obj, key) => {
        obj[key] = member[key];
        return obj;
      }, {});
    editedField["medication_change_date"] = new Date()
      .toJSON()
      .slice(0, 10)
      .replace(/-/g, "-");

    return editedField;
  }

  async startChangingDosage(index) {
    this.changeId = index;
    this.changeDosageField = await this.getDosageChangeField(index);
  }

  async stopChangingDosage() {
    this.changeId = null;
    this.changeDosageField = {
      medication_dosage: [],
      medication_change_date: new Date()
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "-"),
    };
  }
}

class DataAdd {
  variables = [];
  initFields = [];
  addingList: any = [];
  desc = "";
  dateTimeFields = [];
  bodyField = "";
  endpoint = "";
  defaultDateTime = "";
  defaultDateTimeName = "";
  defaultDateTimeFields = [];
  customDateTime = "";
  reloadToTab = "";
  requiredFields = [];

  diagOptions = {};
  requiredEitherOr = [];
  required = [];

  units = {
    // vitals
    "heart rate": ["bpm"],
    "blood pressure systolic": ["mmHg"],
    "blood pressure diastolic": ["mmHg"],
    SpO2: ["%"],
    // medical
    weight: ["kg"],
    "dry weight": ["kg"],
    temperature: ["C"],
    "respiratory rate": ["bpm"],
    // lab
    "NT-proBNP": ["pg/ml", "pmol/l"],
    BNP: ["pg/ml", "pmol/l"],
    creatinine: ["umol/l", "mg/dl"],
    urea: ["mmol/l"],
    hemoglobin: ["g/l", "g/dl"],
    // ECHO
    LVEF: ["%"],
    "TR velocity": ["m/s"],
    "TR gradient": ["mmHg"],
    LAVi: ["ml/m2"],
    "stroke volume on echo": ["ml"],
    RVSP: ["mmHg"],
    // RHC
    "RHC right atrial pressure mean": ["mmHg"],
    "RHC ventricular pressure systolic": ["mmHg"],
    "RHC ventricular pressure diastolic": ["mmHg"],
    "RHC pulmonary capillary wedge pressure": ["mmHg"],
    "RHC pulmonary artery pressure systolic": ["mmHg"],
    "RHC pulmonary artery pressure diastolic": ["mmHg"],
    "RHC pulmonary artery pressure mean": ["mmHg"],
    "RHC stroke volume - Fick": ["ml"],
    "RHC stroke volume - TD": ["ml"],
    "RHC stroke volume index": ["ml/m2"],
    "RHC cardiac output - Fick": ["l/min"],
    "RHC cardiac output - TD": ["l/min"],
    "RHC cardiac index - Fick": ["l/min/m2"],
    "RHC cardiac index - TD": ["l/min/m2"],
    "RHC pulmonary vascular resistance - Fick": ["WU"],
    "RHC pulmonary vascular resistance - TD": ["WU"],
    "RHC pulmonary vascular resistance index": ["WU/m2"],
    "RHC total pulmonary resistance": ["WU"],
    "RHC pulmonary arterial compliance": ["ml/mmHg"],
    "RHC transpulmonary gradient": ["mmHg"],
    "RHC diastolic transpulmonary gradient": ["mmHg"],
    "RHC mixed venous oxygen saturation": ["%"],
    "RHC arterial oxygen saturation": ["%"],
    "RHC systemic blood pressure systolic": ["mmHg"],
    "RHC systemic blood pressure diastolic": ["mmHg"],
  };

  constructor(public api: ApiConnector, public patient: any) {}

  getUnits(variable: string, first: boolean = false) {
    if (!this.units.hasOwnProperty(variable)) {
      if (first) {
        return null;
      } else {
        return [];
      }
    }
    const units = this.units[variable];
    if (first) {
      return units[0];
    }
    return units;
  }

  async removeItem(index: number) {
    this.addingList.splice(index, 1);
  }

  async addItem() {
    this.addingList.push({});
  }

  async setAllDatetimes(datetime, date: boolean = false) {
    if (!date) {
      var datetime: any = await parseDatetimeToLocal(datetime);
    }
    for (const field of this.addingList) {
      for (const key of this.defaultDateTimeFields) {
        field[key] = datetime;
      }
    }
    if (datetime === this.defaultDateTime) {
      this.customDateTime = "";
    } else {
      this.customDateTime = datetime;
    }
  }

  async customSanitize(field: object) {
    return field;
  }

  async postBulk() {
    // filter by required
    const filteredData =
      this.requiredFields.length === 0
        ? this.addingList
        : this.addingList.filter((item) =>
            this.requiredFields.every(
              (field) => item.hasOwnProperty(field) && item[field] !== null
            )
          );
    for (let i = 0; i < filteredData.length; i++) {
      filteredData[i].patient_id = this.patient.patientId;
      filteredData[i] = await this.customSanitize(filteredData[i]);
      for (const key in filteredData[i]) {
        if (
          this.dateTimeFields.includes(key) &&
          filteredData[i].hasOwnProperty(key)
        ) {
          var datetime = filteredData[i][key];
          filteredData[i][key] = await dateTimeISOString(datetime);
        }
      }
    }

    let body = {};
    body["number_of_datapoints"] = filteredData.length;
    body[this.bodyField] = filteredData;
    try {
      const response = await this.api.post(this.endpoint, body);
      if (response != null) {
        window.PineconeRouter.context.navigate(
          `/patient/${this.patient.patientId}/${this.reloadToTab}`
        );
        window.location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}
export class AddVitals extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "heart rate",
      "blood pressure systolic",
      "blood pressure diastolic",
      "SpO2",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Patient vitals";
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/data";
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
    this.reloadToTab = "vitals";
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await this.getUnits(variable, true),
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}
export class AddSymptoms extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = ["shortness of breath", "fatigue score"];
    this.initFields = [
      "symptom_name",
      "symptom_date",
      "symptom_change_in_last_six_m",
    ];
    this.desc = "HF-related symptoms";
    this.bodyField = "symptoms";
    this.endpoint = "hf/symptoms";
    this.defaultDateTimeName = "Today";
    this.defaultDateTimeFields = ["symptom_date"];
    this.requiredFields = [
      "symptom_name",
      "symptom_date",
      "symptom_value",
      "symptom_change_in_last_six_m",
    ];
    this.reloadToTab = "symptoms";
  }

  async initEmpty() {
    this.defaultDateTime = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        symptom_date: this.defaultDateTime,
        symptom_name: variable,
        symptom_change_in_last_six_m: false,
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddEvents extends DataAdd {
  // constants
  eventTypes = {
    patient_contact: "Patient contact",
    medical_call: "Medical call",
    medication_change: "Medication change",
    check_up: "Check-up",
    labs: "Labs",
    hospitalization: "Hospitalization",
    patient_non_adherence: "Patient non-adherence",
    physician_notified: "Treating physician notified",
  };

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = ["event_date"];
    this.desc = "Patient's events";
    this.defaultDateTimeName = "Today";
    this.defaultDateTimeFields = ["event_date"];
    this.endpoint = "events";
    this.reloadToTab = "events";
  }

  async initEmpty() {
    this.defaultDateTime = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    var empty = [];
    const temp = {
      event_date: this.defaultDateTime,
      event_type: null,
    };
    empty.push(temp);
    this.addingList = empty;
  }

  async addItem() {
    this.addingList.push({
      event_date: this.defaultDateTime,
      event_type: null,
    });
  }

  async postBulk() {
    for (let i = 0; i < this.addingList.length; i++) {
      this.addingList[i].patient_id = this.patient.patientId;
      this.addingList[i].event_timestamp = await dateTimeISOString(new Date());
      this.addingList[i] = await this.customSanitize(this.addingList[i]);
      for (const key in this.addingList[i]) {
        if (
          this.dateTimeFields.includes(key) &&
          this.addingList[i].hasOwnProperty(key)
        ) {
          var datetime = this.addingList[i][key];
          this.addingList[i][key] = await dateTimeISOString(datetime);
        }
      }
    }
    // POST one by one
    for (let i = this.addingList.length - 1; i >= 0; i--) {
      try {
        const response = await this.api.post(this.endpoint, this.addingList[i]);
        if (response != null) {
          this.addingList.splice(i, 1);
        }
      } catch (error) {
        console.error("POST error:", error);
      }
    }
    window.PineconeRouter.context.navigate(
      `/patient/${this.patient.patientId}/${this.reloadToTab}`
    );
    window.location.reload();
  }
}

export class AddThresholds extends DataAdd {
  // constants
  threshTables = {
    medicaldata: "Medical data (Manual)",
    symptoms: "Symptoms (Manual)",
    ppg_derived: "PPG-derived (from Oximeter)",
    sq_computed: "SQ-computed (our algorithm)",
  };
  threshTypes = ["low", "high"];
  threshSetting = ["value check", "value change check"];
  changeTypes = ["increase", "decrease"];
  canChangeBased = {
    "fatigue score": "increase",
    "shortness of breath": "increase",
    "SpO2: mean": "decrease",
  };
  defaultThreshTypes = { increase: "high", decrease: "low" };
  availableThresholds = {
    "diastolic reserve index (median)": "sq_computed",
    "blood pressure systolic": "medicaldata",
    "blood pressure diastolic": "medicaldata",
    "heart rate": "medicaldata",
    "fatigue score": "symptoms",
    "shortness of breath": "symptoms",
    "HR: mean": "ppg_derived",
    "SpO2: mean": "ppg_derived",
  };

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = [
      "threshold_variable",
      "thresh_setting",
      "threshold_type",
    ];
    this.desc = "Patient's thresholds";
    this.endpoint = "thresholds";
    this.reloadToTab = "thresholds";
  }
  async initEmpty() {
    var empty = [];
    const temp = {
      threshold_variable: null,
      threshold_variable_table: null,
      thresh_setting: "value check",
      threshold_type: null,
    };
    empty.push(temp);
    this.addingList = empty;
  }

  async addItem() {
    this.addingList.push({
      threshold_variable: null,
      threshold_variable_table: null,
      thresh_setting: "value check",
      threshold_type: null,
    });
  }

  async postBulk() {
    for (let i = 0; i < this.addingList.length; i++) {
      this.addingList[i].patient_id = this.patient.patientId;
      if (this.addingList[i]["thresh_setting"] === "value change check") {
        this.addingList[i].threshold_type =
          this.defaultThreshTypes[
            this.canChangeBased[this.addingList[i].threshold_variable]
          ];
        this.addingList[i].threshold_variable += `: ${
          this.canChangeBased[this.addingList[i].threshold_variable]
        }`;
      }
      delete this.addingList[i]["thresh_setting"];
    }
    // POST one by one
    for (let i = this.addingList.length - 1; i >= 0; i--) {
      try {
        const response = await this.api.post(this.endpoint, this.addingList[i]);
        if (response != null) {
          this.addingList.splice(i, 1);
        }
      } catch (error) {
        console.error("POST error:", error);
      }
    }
    window.PineconeRouter.context.navigate(
      `/patient/${this.patient.patientId}/${this.reloadToTab}`
    );
    window.location.reload();
  }
}

export class AddMedication extends DataAdd {
  freeTextPlaceHolder = "Specify...";

  // constants
  medicationOptions = {
    "Loop diuretics": ["Furosemide", "Torasemide"],
    "Thiazide diuretics": ["Hydrochlorothiazide"],
    "Other diuretics": ["Indapamide"],
    MRAs: ["Spironolactone", "Eplerenone", "Finerenone"],
    "SGLT2 inhibitors": ["Empagliflozine", "Dapagliflozine"],
    Betablockers: [
      "Bisoprolol",
      "Metoprolol succinate",
      "Nebivolol",
      "Carvedilol",
    ],
    "RAAS inhibitors: ARNI": [
      "Entresto 24mg / 26mg",
      "Entresto 49mg / 51mg",
      "Entresto 97mg / 103mg",
    ],
    "RAAS inhibitors: ACE inhibitors": [
      "Perindopril",
      "Ramipril",
      "Trandolapril",
      "Lisinopril",
      "Chinapril",
    ],
    "RAAS inhibitors: ARB": [
      "Valsartan",
      "Candesartan",
      "Irbesartan",
      "Telmisartan",
      "Losartan",
    ],
    Vasodilators: ["Izosorbid-mononitrate", "Molsidomine", "Vericiguat"],
    "GLP1-RA": ["Semaglutide", "Liraglutide"],
    Other: ["Digoxin", "Ivabradine"],
  };
  allowFreeText = [
    "Other diuretics",
    "Betablockers",
    "RAAS inhibitors: ACE inhibitors",
    "RAAS inhibitors: ARB",
    "Vasodilators",
    "Other",
  ];

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [];
    this.initFields = [
      "medication_dosage",
      "medication_frequency_regular_repeat_every",
    ];
    this.desc = "Medications";
    this.bodyField = "medications";
    this.endpoint = "medications/bulk";
    this.defaultDateTimeName = "Unset (date not required)";
    this.defaultDateTimeFields = ["medication_started"];
    this.reloadToTab = "medications";
  }

  async customSanitize(field) {
    if (
      field.medication_name === this.freeTextPlaceHolder &&
      this.allowFreeText.includes(field.medication_group)
    ) {
      field.medication_name = field.freeTextValue;
      delete field.freeTextValue;
    }
    return field;
  }

  async initEmpty() {
    this.defaultDateTime = null;
    var empty = [];
    const temp = {
      medication_group: null,
      medication_name: null,
      medication_frequency_regular_repeat_every: 1,
      medication_dosage: [0, 0, 0, 0],
    };
    empty.push(temp);

    this.addingList = empty;
  }

  async addItem() {
    this.addingList.push({
      medication_group: null,
      medication_name: null,
      medication_frequency_regular_repeat_every: 1,
      medication_dosage: [0, 0, 0, 0],
    });
  }

  disabledDoseUnit(field) {
    if (field.medication_name != null) {
      if (field.medication_name.startsWith("Entresto")) {
        return true;
      }
    }
    return false;
  }
}

export class AddDiags extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.initFields = ["diagnosis_name", "diagnosed_at", "diagnosis_value"];
    this.desc = "Comorbidities for HF study";
    this.bodyField = "diagnoses";
    this.endpoint = "hf/comorbidities";
    this.defaultDateTimeName = "Unset (date not required)";
    this.defaultDateTimeFields = ["diagnosed_at"];
    this.reloadToTab = "diags";
  }

  async initEmpty() {
    this.defaultDateTime = null;
    var empty = [];
    for (const variable of Object.keys(this.patient.diagOptions)) {
      const temp = {
        diagnosis_name: variable,
        diagnosed_at: this.defaultDateTime,
        diagnosis_value: this.patient.diagOptions[variable][0],
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }

  async addItem() {
    this.addingList.push({
      diagnosis_name: null,
      diagnosed_at: this.defaultDateTime,
    });
  }

  async postBulk() {
    this.addingList = this.addingList.filter(
      (diag) => diag.diagnosis_value != "No"
    );
    await super.postBulk();
  }
}

export class AddLabs extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "NT-proBNP",
      "BNP",
      "urea",
      "creatinine",
      "hemoglobin",
      "hematocrit",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Patient laboratory data";
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/data";
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.reloadToTab = "labs";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await this.getUnits(variable, true),
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddBasics extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "weight",
      "dry weight",
      "temperature",
      "respiratory rate",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Patient laboratory data";
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/data";
    this.defaultDateTimeName = "Patient added to our DB";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.reloadToTab = "basics";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(
      this.patient.data.created_at
    );
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await this.getUnits(variable, true),
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddRHC extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "RHC right atrial pressure mean",
      "RHC ventricular pressure systolic",
      "RHC ventricular pressure diastolic",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure systolic",
      "RHC pulmonary artery pressure diastolic",
      "RHC pulmonary artery pressure mean",
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC stroke volume index",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
      "RHC cardiac index - Fick",
      "RHC cardiac index - TD",
      "RHC pulmonary vascular resistance - Fick",
      "RHC pulmonary vascular resistance - TD",
      "RHC pulmonary vascular resistance index",
      "RHC total pulmonary resistance",
      "RHC pulmonary arterial compliance",
      "RHC transpulmonary gradient",
      "RHC diastolic transpulmonary gradient",
      "RHC mixed venous oxygen saturation",
      "RHC arterial oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
      "study",
    ];
    this.desc = "Right Heart Catheterization exams";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];

    // constants
    this.required = [
      "RHC right atrial pressure mean",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure mean",
      "RHC mixed venous oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
    ];
    this.requiredEitherOr = [
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
    ];
    this.bodyField = "medical_data";
    this.endpoint = "hf/exams/rhc";
    this.reloadToTab = "exams";
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await this.getUnits(variable, true),
        study: "HF validation",
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddEcho extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "LVEF",
      "E/A",
      "E/e' average",
      "TR velocity",
      "TR gradient",
      "LAVi",
      "elevated LV filling pressure",
      "stroke volume on echo",
      "AoS",
      "AoR",
      "MR",
      "MS",
      "TR",
      "PuR",
      "RVSP",
      "B-lines on lung-ultrasound",
      "pleural effusion",
      "ascites",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
      "study",
    ];
    this.desc = "ECHO exams";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "hf/exams/echo";
    this.reloadToTab = "exams";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await await this.getUnits(variable, true),
        study: "HF validation",
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddOtherExams extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "ECG rhythm",
      "ECG premature contractions",
      "leg edema",
      "jugular venous distention",
      "elevated LV filling pressure final outcome",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
      "study",
    ];
    this.desc = "Other (ECG) exams";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "medicaldata/bulk";
    this.reloadToTab = "exams";
    this.requiredFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_value",
    ];
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    var empty = [];
    for (const variable of this.variables) {
      const temp = {
        measurement_datetime: this.defaultDateTime,
        measurement_type: variable,
        measurement_unit: await await this.getUnits(variable, true),
        study: "HF validation",
      };
      empty.push(temp);
    }
    this.addingList = empty;
  }
}

export class AddPPG extends DataAdd {
  ppgFile = new FormData();

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.desc = "PPG data from SmartCare device (others not tested)";
    this.endpoint = "";
    this.dateTimeFields = ["measurement_datetime"];
    this.defaultDateTime = null;
    this.reloadToTab = "ppg";
  }

  async initEmpty() {
    this.addingList = {
      measurement_datetime: this.defaultDateTime,
      measurement_condition: null,
      measurement_device: "SmartCare BM2000A",
      measurement_origin: "finger",
      measurement_mode: 1,
      quality_flag: 1,
      comment: "",
    };
  }

  uploadFile(event) {
    const file = event.target.files[0];
    this.ppgFile.set("ppg_file", file);
  }

  async objectToQueryString(obj) {
    return Object.keys(obj)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`
      )
      .join("&");
  }

  async post() {
    this.addingList.patient_id = this.patient.patientId;
    for (const key in this.addingList) {
      if (
        this.dateTimeFields.includes(key) &&
        this.addingList.hasOwnProperty(key)
      ) {
        var datetime = this.addingList[key];
        this.addingList[key] = new Date(
          await parseDatetimeToLocal(datetime)
        ).toISOString();
      }
    }
    const queryParams = await this.objectToQueryString(this.addingList);

    const endpointRoute = `ppg/smartcare?${queryParams}`;
    const headers = await this.api.postPutHeaders();
    delete headers["Content-Type"];
    try {
      const response = await this.api.request(
        endpointRoute,
        "POST",
        headers,
        this.ppgFile,
        false
      );
      if (response != null) {
        window.PineconeRouter.context.navigate(
          `/patient/${this.patient.patientId}/${this.reloadToTab}`
        );
        window.location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}

export class AddAnyData extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.variables = [
      "heart rate",
      "blood pressure systolic",
      "blood pressure diastolic",
      "SpO2",
      "NT-proBNP",
      "BNP",
      "urea",
      "creatinine",
      "hemoglobin",
      "hematocrit",
      "weight",
      "dry weight",
      "temperature",
      "respiratory rate",
      "ECG rhythm",
      "ECG premature contractions",
      "leg edema",
      "jugular venous distention",
      "LVEF",
      "E/A",
      "E/e' average",
      "TR velocity",
      "TR gradient",
      "LAVi",
      "elevated LV filling pressure",
      "stroke volume on echo",
      "AoS",
      "AoR",
      "MR",
      "MS",
      "TR",
      "PuR",
      "RVSP",
      "B-lines on lung-ultrasound",
      "pleural effusion",
      "ascites",
      "RHC right atrial pressure mean",
      "RHC ventricular pressure systolic",
      "RHC ventricular pressure diastolic",
      "RHC pulmonary capillary wedge pressure",
      "RHC pulmonary artery pressure systolic",
      "RHC pulmonary artery pressure diastolic",
      "RHC pulmonary artery pressure mean",
      "RHC stroke volume - Fick",
      "RHC stroke volume - TD",
      "RHC stroke volume index",
      "RHC cardiac output - Fick",
      "RHC cardiac output - TD",
      "RHC cardiac index - Fick",
      "RHC cardiac index - TD",
      "RHC pulmonary vascular resistance - Fick",
      "RHC pulmonary vascular resistance - TD",
      "RHC pulmonary vascular resistance index",
      "RHC total pulmonary resistance",
      "RHC pulmonary arterial compliance",
      "RHC transpulmonary gradient",
      "RHC diastolic transpulmonary gradient",
      "RHC mixed venous oxygen saturation",
      "RHC arterial oxygen saturation",
      "RHC systemic blood pressure systolic",
      "RHC systemic blood pressure diastolic",
      "elevated LV filling pressure final outcome",
    ];
    this.initFields = [
      "measurement_datetime",
      "measurement_type",
      "measurement_unit",
    ];
    this.desc = "Add (almost) any additional data";
    this.defaultDateTimeName = "Now";
    this.defaultDateTimeFields = ["measurement_datetime"];
    this.dateTimeFields = ["measurement_datetime"];
    this.bodyField = "medical_data";
    this.endpoint = "medicaldata/bulk";
    this.reloadToTab = "any";
  }

  async initEmpty() {
    this.defaultDateTime = await parseDatetimeToLocal(new Date());
    this.addingList = [];
  }

  async addItem() {
    this.addingList.push({
      measurement_datetime: this.defaultDateTime,
      measurement_type: null,
      measurement_value: null,
      measurement_unit: null,
    });
  }

  async customSanitize(field) {
    field.measurement_unit = await this.getUnits(field.measurement_type, true);
    return field;
  }
}

export class AddPatient extends DataAdd {
  isHF = false;
  createUser = false;
  dataHF = { etiology: null, type: null, diagnosed: null };
  dataUser = { preferred_language: "sk", token_expiry_timedelta: "P1D" };

  physiciansModal = null;
  usersModal = null;

  // constants
  HFOptions = {
    etiology: ["Ischemic", "Non-ischemic", "Unknown"],
    type: ["HFpEF", "HFmrEF", "HFrEF"],
  };

  tokenExpiries = {
    P1D: "1 day",
    P2D: "2 days",
    P1W: "1 week",
    P2W: "2 weeks",
  };

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      patient_study: [null],
      date_of_birth: null,
      sex: null,
      height: null,
      description: null,
      realm: 1,
      race: "Unknown / Not Reported",
      name: null,
      residence: "N/A",
      health_insurance: null,
      id_number: null,
      email: null,
      phone: null,
      append_to_physicians: [],
      append_to_physician_users: [],
    };
  }

  async initEmpty() {
    if (this.api.amILevel3) {
      const users = new Users(this.api);
      await users.initPhysiciansOnly();
      this.usersModal = new ModalWindow(users);
      await this.usersModal.init("/templates/modals/users.html");

      const physiciansResponse = await this.api.get("physicians");
      const physicians = new Physicians(physiciansResponse.physicians);
      await physicians.init();
      this.physiciansModal = new ModalWindow(physicians);
      await this.physiciansModal.init("/templates/modals/physicians.html");
    }
  }

  async choosePhysiciansForPatient() {
    this.physiciansModal.modalData.filter["realm"] = [this.addingList.realm];
    this.physiciansModal.modalText = "Choose physicians for a new patient";
    this.physiciansModal.chooseSingle = false;

    await this.physiciansModal.openModal();
  }

  physiciansLength() {
    if (this.api.amILevel3) {
      return this.physiciansModal.finalMultiChoice.length;
    } else {
      return null;
    }
  }

  usersLength() {
    if (this.api.amILevel3) {
      return this.usersModal.finalMultiChoice.length;
    } else {
      return null;
    }
  }

  async chooseUsersForPatient() {
    this.usersModal.modalText = "Choose managing users for a new patient";
    this.usersModal.chooseSingle = false;

    await this.usersModal.openModal();
  }

  async postPatient(physicians: string[] = [], users: string[] = []) {
    this.addingList["append_to_physicians"] = physicians;
    this.addingList["append_to_physician_users"] = users;
    try {
      if (this.createUser) {
        var resPatient = await this.api.post("patients/patient-and-user", {
          ...this.addingList,
          ...this.dataUser,
        });
      } else {
        var resPatient = await this.api.post("patients", this.addingList);
      }
      if (resPatient != null) {
        return resPatient.patient_id;
      } else {
        return null;
      }
    } catch (error) {
      console.error("POST error:", error);
      return null;
    }
  }

  async post() {
    const patientId = await this.postPatient();
    if (patientId != null && this.isHF) {
      const diagEtiology = {
        diagnosis_name: "HF etiology",
        diagnosis_value: this.dataHF["etiology"],
        diagnosed_at: this.dataHF["diagnosed"],
        patient_id: patientId,
      };
      const diagType = {
        diagnosis_name: "HF type",
        diagnosis_value: this.dataHF["type"],
        diagnosed_at: this.dataHF["diagnosed"],
        patient_id: patientId,
      };
      var diags = [diagEtiology, diagType];
      const body = { number_of_datapoints: diags.length, diagnoses: diags };
      try {
        var resDiags = await this.api.post("diagnoses/bulk", body);
      } catch (error) {
        console.error("POST error:", error);
        var resDiags = null;
      }
    }
    if (patientId != null && resDiags != null) {
      window.PineconeRouter.context.navigate(`/patient/${patientId}`);
    }
  }
}

export class AddUser extends DataAdd {
  // constants
  allRoles = [
    "patient-ppg-app",
    "physician-ppg-app",
    "study-ppg-app",
    "patient",
    "physician",
    "study-physician",
    "seerlinq-user",
  ];

  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      username: null,
      email: null,
      password: null,
      role: null,
      preferred_language: "sk",
      connected_patient_id: null,
      managed_patient_ids: [],
    };
  }

  async initEmpty() {}
  async post(connected = null, managed = []) {
    this.addingList["connected_patient_id"] = connected;
    this.addingList["managed_patient_ids"] = managed;
    try {
      const response = await this.api.post("users", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/users");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}

export class AddClinic extends DataAdd {
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      name: null,
      ambulance: null,
      address: null,
      country: null,
      realm: 1,
      phone: null,
      email: null,
      physicians_uuids: [],
    };
  }

  async initEmpty() {}

  async post(physicians: string[] = []) {
    this.addingList["physicians_uuids"] = physicians;
    try {
      const response = await this.api.post("clinics", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/clinics");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}

export class AddPhysician extends DataAdd {
  phoneList: ListDataField;
  constructor(api: ApiConnector, patient: any) {
    super(api, patient);
    this.addingList = {
      surname: null,
      given_name: null,
      titles: null,
      realm: 1,
      email: null,
      phone: [],
      connected_user_uuid: null,
      clinics_uuids: [],
      patient_ids: [],
    };

    this.phoneList = new ListDataField();
  }

  async initEmpty() {}

  async post(
    phones: string[] = [],
    clinics: string[] = [],
    patients: number[] = [],
    user_uuid: string = null
  ) {
    this.addingList["phone"] = phones;
    this.addingList["clinics_uuids"] = clinics;
    this.addingList["patient_ids"] = patients;
    if (user_uuid != null) {
      this.addingList["connected_user_uuid"] = user_uuid;
    }
    try {
      const response = await this.api.post("physicians", this.addingList);
      if (response != null) {
        window.PineconeRouter.context.navigate("/admin/physicians");
        location.reload();
      }
    } catch (error) {
      console.error("POST error:", error);
    }
  }
}

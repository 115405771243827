import { ApiConnector } from "./apiConnector";
import {
  Computed,
  consentOk,
  DerivedData,
  Diagnoses,
  Events,
  isPaperConsent,
  MedData,
  Medications,
  MergedMedData,
  PPGs,
  Schedules,
  Symptoms,
  Thresholds,
} from "./dataUtils";
import { ComputedPlot, MedicalPlot, SymptomPlot } from "./plotting";
import { dateTimeISOString, dateTimeOrNull } from "./utils";

export class Patient {
  patientId = null;
  loaded = false;
  data = null;
  vitals = null;
  symptoms = null;
  events = null;
  medications = null;
  diags = null;
  labs = null;
  exams = null;
  basicMed = null;
  ppgs = null;
  schedules = null;
  physicianMessage = null;
  heartCore = null;

  diagOptions = {};
  minDRIHeartCore = 4;

  driData: any;
  driDataPlot: any;
  vitalsPlot: any;
  symptomPlot: any;
  thresholds: any;
  labsPlot: any;
  basicMedPlot: any;

  constructor(public api: ApiConnector) {}

  async fetchBasic(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();
    this.data = await this.api.get(`patients/${patientId}`);
  }

  async fetch(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();
    this.data = await this.api.get(`patients/${patientId}`);

    // DRI
    const allComputed = await this.api.get(
      `patients/${patientId}/computed?all_versions=true`
    );
    this.driData = new Computed(allComputed.computed_data);
    await this.driData.init();
    await this.driData.filterDRIOnly();
    this.driDataPlot = new ComputedPlot(
      this.driData,
      ["diastolic reserve index"],
      "driDataPlot"
    );
    await this.driDataPlot.initPlot();

    // vitals
    const vitals = await this.api.get(`hf/vitals/${patientId}`);
    this.vitals = new MergedMedData(vitals.medical_data, this.api.amILevel3);
    // merge with SpO2 and HR from derived
    const derived = await this.api.get(`patients/${patientId}/derived`);
    const SpO2 = new DerivedData(derived.derived_data);
    await SpO2.init();
    await SpO2.filterSpO2();
    const HRDerived = new DerivedData(derived.derived_data);
    await HRDerived.init();
    await HRDerived.filterHR();
    const varMapping = {
      "SpO2: mean": "SpO2",
      "HR: mean": "heart rate",
    };
    await this.vitals.mergeWithPPGDerived(
      [SpO2, HRDerived],
      "measurement_type",
      varMapping
    );
    await this.vitals.init();
    this.vitalsPlot = new MedicalPlot(
      this.vitals,
      [
        "heart rate",
        "blood pressure diastolic",
        "blood pressure systolic",
        "SpO2",
      ],
      "vitalsPlot"
    );
    await this.vitalsPlot.initPlot();

    // symptoms
    const symptoms = await this.api.get(`hf/symptoms/${patientId}`);
    this.symptoms = new Symptoms(symptoms.symptoms, this.api.amILevel3);
    await this.symptoms.init();
    this.symptomPlot = new SymptomPlot(
      this.symptoms,
      ["shortness of breath", "fatigue score"],
      "symptomPlot"
    );
    await this.symptomPlot.initPlot();

    // events and thresholds
    if (this.api.amILevel3) {
      this.events = new Events(this.data.events);
      await this.events.init();
      this.thresholds = new Thresholds(this.data.thresholds);
      await this.thresholds.init();
    }

    // medications
    const medications = await this.api.get(`patients/${patientId}/medications`);
    this.medications = new Medications(
      medications.medications,
      this.api.amILevel3
    );
    await this.medications.init();

    // comorbidities
    const diags = await this.api.get(`hf/comorbidities/${patientId}`);
    this.diags = new Diagnoses(diags.diagnoses, this.api.amILevel3);
    await this.diags.init();
    await this.getDiagnosesOptions();

    // labs
    const labs = await this.api.get(`hf/lab-data/${patientId}`);
    this.labs = new MedData(labs.medical_data, this.api.amILevel3);
    await this.labs.init();
    this.labsPlot = new MedicalPlot(
      this.labs,
      ["NT-proBNP", "BNP", "urea", "creatinine", "hemoglobin", "hematocrit"],
      "labsPlot"
    );
    await this.labsPlot.initPlot();

    // exams
    const exams = await this.api.get(`hf/exams/${patientId}`);
    this.exams = new MedData(exams.medical_data, this.api.amILevel3);
    await this.exams.init();

    // basic medical data
    const basicMed = await this.api.get(`hf/basic-med-data/${patientId}`);
    this.basicMed = new MedData(basicMed.medical_data, this.api.amILevel3);
    await this.basicMed.init();
    this.basicMedPlot = new MedicalPlot(
      this.basicMed,
      ["weight", "dry weight", "temperature", "respiratory rate"],
      "basicMedPlot"
    );
    await this.basicMedPlot.initPlot();

    // PPG data
    const ppgs = await this.api.get(`patients/${patientId}/ppgs`);
    this.ppgs = new PPGs(ppgs.ppg_data);
    await this.ppgs.init();

    const schedules = await this.api.get(
      `schedules?patient_ids=${patientId}&schedule_type=ppg`
    );
    this.schedules = new Schedules(schedules.schedules);
    await this.schedules.init();

    this.physicianMessage = await this.api.get(`messaging/${patientId}`);

    if (this.api.amILevel3) {
      this.heartCore = this.data.heart_core;
      this.heartCore.last_ppg = await dateTimeOrNull(
        this.heartCore.last_ppg,
        true
      );
      this.heartCore.reportStart = await dateTimeOrNull(
        this.heartCore.report_start_date,
        false
      );
      this.heartCore.dris = await Promise.all(
        this.heartCore.last_5_dris.map(async ([datetime, floatVal]) => [
          await dateTimeOrNull(datetime),
          parseFloat(floatVal.toFixed(1)),
        ])
      );
    }

    this.loaded = true;
  }

  consentOk() {
    return consentOk(this.data);
  }

  isPaperConsent() {
    return isPaperConsent(this.data);
  }

  async sanitizeBody(body: object, datetimeIso: string[] = []) {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] === "") {
          body[key] = null;
        }
      }
      if (datetimeIso.includes(key) && body.hasOwnProperty(key)) {
        var datetime = body[key];
        body[key] = await dateTimeISOString(datetime);
      }
    }
    // remove null
    body = Object.fromEntries(
      Object.entries(body).filter(([key, value]) => value !== null)
    );
    return body;
  }

  async updatePatientField(body: object) {
    const cleanBody = await this.sanitizeBody(body);
    await this.api.put(`patients/${this.patientId}`, cleanBody);
    await this.fetch(this.patientId);
  }

  async updateItem(
    endpoint: string,
    uuid: string,
    body: object,
    datetimeIso: string[] = []
  ) {
    const cleanBody = await this.sanitizeBody(body, datetimeIso);
    await this.api.put(`${endpoint}/${uuid}`, cleanBody);
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async getDiagnosesOptions() {
    const response = await this.api.get("hf/comorbidities");
    this.diagOptions = response["comorbidities"];
  }

  async numDRIDataPoints() {
    return this.driData.data.length;
  }

  floatRounding(value: number | string, decimals: number = 3) {
    if (typeof value === "number") {
      return value.toFixed(decimals);
    }
    return value;
  }
}

import Alpine from "alpinejs";
import PineconeRouter from "pinecone-router";
import { AdminConsole } from "./adminUtils";
import { ApiConnector } from "./apiConnector";
import { apiRoute } from "./config";
import {
  AddAnyData,
  AddBasics,
  AddClinic,
  AddDiags,
  AddEcho,
  AddEvents,
  AddLabs,
  AddMedication,
  AddOtherExams,
  AddPatient,
  AddPhysician,
  AddPPG,
  AddRHC,
  AddSymptoms,
  AddThresholds,
  AddUser,
  AddVitals,
  Alerts,
  Clinics,
  Patients,
  Physicians,
  Users,
} from "./dataUtils";
import { Patient } from "./patient";
import { SimplePlot } from "./plotting.ts";
import {
  PPGQuickLook,
  SeerlinqDRIHeartCore,
  SeerlinqHCReport,
} from "./quickLookAnalyses";
import { initSentry } from "./sentry";

initSentry();

const seerlinqApi = new ApiConnector(apiRoute);
const clvVersion = "2.5.2";

export function app() {
  return {
    seerlinqApi: seerlinqApi,
    clvVersion: clvVersion,
    darkMode: localStorage.getItem("darkMode") === "true",

    // basics
    loading: true,
    loadingPatients: true,
    loadingAdmin: true,
    defaultApiPageSize: 25,
    patients: [],

    // patient loading and tabs
    loadingPatient: true,
    patient: null,
    ppgQuickLook: null,
    driHeartCore: null,
    heartCoreReport: null,
    activePatientModal: null,
    patientTabToggling: true,
    patientModalContent: "",

    // admin
    adminShit: null,
    activeAdminModal: null,
    adminTabToggling: true,
    adminModalContent: "",

    // data adding
    dataAdd: {},
    dataInited: false,

    togglingDataAdd: false,
    addingActive: false,
    addingId: null,
    dataAddContent: "",

    // editing helpers
    editingId: null,
    editedField: {},

    // app-wide constants
    patientStates: {
      0: "Normal",
      1: "High risk",
    },
    patientStatuses: {
      0: "Test",
      1: "Active",
      2: "Not active",
      3: "Marked for deletion",
    },
    seerlinqStudies: {
      "-1": "No study",
      0: "Commercial",
      1: "Seerlinq-validation study",
      2: "Test home-monitoring",
      3: "#STOP-DHF",
      4: "Clinic: single measurement",
    },
    seerlinqRealms: { 0: "Dummy", 1: "Central Europe" },
    informedConsent: { 0: "None", 1: "Telemonitoring", 2: "Paper" },
    diagConfidence: [0, 25, 50, 75, 100],
    ppgConditions: [
      "Condition 1",
      "Condition 2",
      "Condition 3",
      "Condition 4",
      "Condition 5",
    ],
    ppgMeasModes: {
      1: "Seerlinq supervison",
      2: "physician supervision",
      3: "home-monitoring",
    },
    ppgQuality: {
      0: "Data error",
      1: "OK",
      2: "Low signal quality",
      3: "Artefacts",
      4: "Short",
      5: "Extra",
      6: "Testing",
    },
    medicalOptions: {
      // exams basic
      "ECG rhythm": ["SR", "AF", "Paced", "Other"],
      "ECG premature contractions": ["No", "Yes"],
      "leg edema": [0, 1, 2, 3, 4],
      "jugular venous distention": ["No", "Yes"],
      // echo
      "elevated LV filling pressure": ["No", "Yes"],
      AoS: ["None", "Mild", "Moderate", "Severe"],
      AoR: ["None", "Trace", "Mild", "Moderate", "Severe"],
      MR: ["None", "Trace", "Mild", "Moderate", "Severe"],
      MS: ["None", "Mild", "Moderate", "Severe"],
      TR: ["None", "Trace", "Mild", "Moderate", "Severe"],
      PuR: ["None", "Trace", "Mild", "Moderate", "Severe"],
      "B-lines on lung-ultrasound": ["No", "Yes"],
      "pleural effusion": ["None", "Minor", "Major"],
      ascites: ["None", "Minor", "Major"],
      "elevated LV filling pressure final outcome": ["No", "Yes"],
    },
    languages: ["sk", "en"],
    connectedRoles: ["patient-ppg-app", "patient"],
    managingRoles: ["physician-ppg-app", "physician", "study-physician"],

    // basic loaders

    async initIndex() {
      await this.setTheme();
      await this.seerlinqApi.checkLoggedIn();
      this.loading = false;
    },

    async initLogin() {
      await this.seerlinqApi.checkLoggedIn(false);
    },

    async getPatients() {
      this.loadingPatients = true;
      await this.initIndex();
      this.patients = new Patients(this.seerlinqApi, this.defaultApiPageSize);
      // init with HeartCore
      await this.patients.init(true, false);
      this.loadingPatients = false;
      // finish fetching
      await this.patients.fetchNextPage(true, true);
    },

    async initPPGQuickLook() {
      const ppgQuickLook = new PPGQuickLook(this.seerlinqApi);
      this.ppgQuickLook = ppgQuickLook;
    },

    async initHeartCore() {
      const driHeartCore = new SeerlinqDRIHeartCore(this.seerlinqApi);
      this.driHeartCore = driHeartCore;
      const heartCoreReport = new SeerlinqHCReport(this.seerlinqApi);
      this.heartCoreReport = heartCoreReport;
    },

    async loadPatient(patientId: number, activeTab: string = "dri") {
      const patient = new Patient(this.seerlinqApi);
      await patient.fetch(patientId);
      this.patient = patient;
      if (this.seerlinqApi.amILevel3) {
        await this.initPPGQuickLook();
        await this.initHeartCore();
      }
      this.loadingPatient = false;
      // DRI modal is active by default
      await this.switchPatientTab(activeTab);
    },

    async initAdmin(activeTab: string = "clinics") {
      await this.seerlinqApi.checkLoggedIn();
      if (this.seerlinqApi.amILevel3) {
        this.loadingAdmin = true;
        await this.seerlinqApi.checkLoggedIn();
        const clinicsResponse = await this.seerlinqApi.get("clinics");
        const clinics = new Clinics(clinicsResponse.clinics);
        await clinics.init();

        const physiciansResponse = await this.seerlinqApi.get("physicians");
        const physicians = new Physicians(physiciansResponse.physicians);
        await physicians.init();

        const patients = new Patients(
          this.seerlinqApi,
          this.defaultApiPageSize
        );
        await patients.init(false, true);

        const users = new Users(this.seerlinqApi);
        if (this.seerlinqApi.amIAdmin) {
          await users.init();
        } else {
          await users.initPhysiciansOnly();
        }

        this.adminShit = new AdminConsole(
          this.seerlinqApi,
          clinics,
          physicians,
          patients,
          users
        );
        await this.adminShit.init();
        this.loadingAdmin = false;
        await this.switchAdminTab(activeTab);
      }
    },

    // navigation
    async resetAdding() {
      this.addingActive = false;
      this.dataAdd = {};
      this.dataInited = false;
      this.dataAddContent = "";
    },

    async adminNavigate(tab: string, reload: boolean = false) {
      window.PineconeRouter.context.navigate(`/admin/${tab}`);
      if (reload) {
        window.location.reload();
      } else {
        await this.switchAdminTab(tab);
      }
    },

    async switchAdminTab(tab: string) {
      if (tab === "clinics") {
        await this.toggleAdminModal("templates/admins/clinics.html", "clinics");
      } else if (tab === "physicians") {
        await this.toggleAdminModal(
          "templates/admins/physicians.html",
          "physicians"
        );
      } else if (tab === "teams") {
        await this.toggleAdminModal("templates/admins/teams.html", "teams");
      } else if (tab === "limiters") {
        await this.toggleAdminModal(
          "templates/admins/limiters.html",
          "limiters"
        );
      } else if (tab === "users") {
        await this.toggleAdminModal("templates/admins/users.html", "users");
      }
    },

    async toggleAdminModal(filePath: string, modalId: string) {
      this.adminTabToggling = true;
      this.activeAdminModal =
        this.activeAdminModal === modalId ? false : modalId;
      if (this.activeAdminModal) {
        await fetch(filePath)
          .then((response) => response.text())
          .then((html) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            const specificDiv = tempDiv.querySelector("#modal-content");
            this.adminModalContent = specificDiv.innerHTML;
          });
      }

      this.adminTabToggling = false;
    },

    async patientNavigate(patId: number, tab: string, reload: boolean = false) {
      window.PineconeRouter.context.navigate(`/patient/${patId}/${tab}`);
      if (reload) {
        window.location.reload();
      } else {
        await this.switchPatientTab(tab);
      }
    },

    async switchPatientTab(tab: string) {
      await this.resetAdding();
      if (tab === "dri") {
        await this.togglePatientDataModal(
          "/templates/show/dri.html",
          "dri",
          this.patient.computedPlot
        );
      } else if (tab === "vitals") {
        await this.togglePatientDataModal(
          "/templates/show/vitals.html",
          "vitals",
          this.patient.vitalsPlot
        );
      } else if (tab === "symptoms") {
        await this.togglePatientDataModal(
          "/templates/show/symptoms.html",
          "symptoms",
          this.patient.symptomPlot
        );
      } else if (tab === "events") {
        await this.togglePatientDataModal(
          "/templates/show/events.html",
          "events"
        );
      } else if (tab === "thresholds") {
        await this.togglePatientDataModal(
          "/templates/show/thresholds.html",
          "thresholds"
        );
      } else if (tab === "medications") {
        await this.togglePatientDataModal(
          "/templates/show/medications.html",
          "medications"
        );
      } else if (tab === "diags") {
        await this.togglePatientDataModal(
          "/templates/show/diags.html",
          "diags"
        );
      } else if (tab === "labs") {
        await this.togglePatientDataModal(
          "/templates/show/labs.html",
          "labs",
          this.patient.labsPlot
        );
      } else if (tab === "exams") {
        await this.togglePatientDataModal(
          "/templates/show/exams.html",
          "exams"
        );
      } else if (tab === "basics") {
        await this.togglePatientDataModal(
          "/templates/show/basic_med.html",
          "basics",
          this.patient.basicMedPlot
        );
      } else if (tab === "ppg") {
        await this.togglePatientDataModal("/templates/show/ppg.html", "ppg");
      } else if (tab === "any") {
        await this.togglePatientDataModal(
          "/templates/show/any_data.html",
          "any"
        );
        await this.initAdding("any");
      } else {
        await this.togglePatientDataModal(
          "/templates/show/dri.html",
          "dri",
          this.patient.computedPlot
        );
      }
    },

    async togglePatientDataModal(
      filePath: string,
      modalId: string,
      plotHandler: SimplePlot = null
    ) {
      this.patientTabToggling = true;
      this.activePatientModal =
        this.activePatientModal === modalId ? false : modalId;
      if (this.activePatientModal) {
        await fetch(filePath)
          .then((response) => response.text())
          .then((html) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            const specificDiv = tempDiv.querySelector("#modal-content");
            this.patientModalContent = specificDiv.innerHTML;
          });
      }
      if (plotHandler != null) {
        await plotHandler.resetPlot();
        plotHandler.plotType = null;
        plotHandler.plotStart = null;
        plotHandler.plotEnd = null;
      }
      this.patientTabToggling = false;
    },

    async initAlertSystem() {
      await this.seerlinqApi.checkLoggedIn();
      const alerts = await this.seerlinqApi.get("alerts");
      const alertSystem = new Alerts(alerts.alerts);
      await alertSystem.init();

      return alertSystem;
    },

    // editing helpers
    async startEditing(editId: number, init_value: any) {
      this.editingId = editId;
      this.editedField = init_value;
    },

    async stopEditing(resetTo: any = {}) {
      this.editingId = null;
      this.editedField = resetTo;
    },

    // adding data
    async toggleDataAddSection(filePath: string, modalId: string) {
      this.togglingDataAdd = true;
      if (this.addingId === modalId) {
        this.addingActive = !this.addingActive;
      } else {
        this.addingActive = true;
        this.addingId = modalId;
      }

      if (this.addingActive) {
        await this.initAdding(modalId);
        this.dataAddContent = "";
        await fetch(filePath)
          .then((response) => response.text())
          .then((html) => {
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = html;
            const specificDiv = tempDiv.querySelector("#data-add-content");
            this.dataAddContent = specificDiv.innerHTML;
          });
      } else {
        await this.resetAdding();
      }
      this.togglingDataAdd = false;
    },
    async initAdding(type: string) {
      let dataAdd;

      if (type === "symptoms") {
        dataAdd = new AddSymptoms(this.seerlinqApi, this.patient);
      } else if (type === "vitals") {
        dataAdd = new AddVitals(this.seerlinqApi, this.patient);
      } else if (type === "medications") {
        dataAdd = new AddMedication(this.seerlinqApi, this.patient);
      } else if (type === "diags") {
        dataAdd = new AddDiags(this.seerlinqApi, this.patient);
      } else if (type === "labs") {
        dataAdd = new AddLabs(this.seerlinqApi, this.patient);
      } else if (type === "basics") {
        dataAdd = new AddBasics(this.seerlinqApi, this.patient);
      } else if (type === "events") {
        dataAdd = new AddEvents(this.seerlinqApi, this.patient);
      } else if (type === "thresholds") {
        dataAdd = new AddThresholds(this.seerlinqApi, this.patient);
      } else if (type === "rhc") {
        dataAdd = new AddRHC(this.seerlinqApi, this.patient);
      } else if (type === "echo") {
        dataAdd = new AddEcho(this.seerlinqApi, this.patient);
      } else if (type === "exams") {
        dataAdd = new AddOtherExams(this.seerlinqApi, this.patient);
      } else if (type === "ppg") {
        dataAdd = new AddPPG(this.seerlinqApi, this.patient);
      } else if (type === "any") {
        dataAdd = new AddAnyData(this.seerlinqApi, this.patient);
      } else if (type === "patient") {
        await this.seerlinqApi.checkLoggedIn();
        dataAdd = new AddPatient(this.seerlinqApi, null);
      } else if (type === "user") {
        dataAdd = new AddUser(this.seerlinqApi, null);
      } else if (type === "clinic") {
        dataAdd = new AddClinic(this.seerlinqApi, null);
      } else if (type === "physician") {
        dataAdd = new AddPhysician(this.seerlinqApi, null);
      }

      await dataAdd.initEmpty();
      this.dataAdd = dataAdd;
      this.dataInited = true;
    },

    // dark mode

    async setTheme() {
      var darkTheme = document.getElementById(
        "dark-mode-css"
      ) as HTMLLinkElement;
      if (this.darkMode) {
        darkTheme.disabled = false;
      } else {
        darkTheme.disabled = true;
      }
    },

    async toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem("darkMode", this.darkMode);
      await this.setTheme();
    },
  };
}

function main() {
  document.addEventListener("alpine:init", () => {
    window.PineconeRouter.settings.hash = true;
  });

  Alpine.data("app", app);
  Alpine.plugin(PineconeRouter);
  Alpine.start();
}

main();

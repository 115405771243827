// basic settings
const rolesLevel3 = ["admin", "seerlinq-user", "study-physician"];
const rolesCanAddPatients = [
  "physician",
  "study-physician",
  "seerlinq-user",
  "admin",
];

export class ApiConnector {
  apiRoute: string;
  loggedIn: boolean;
  username: string | null;
  amIAdmin: boolean;
  canIAddPatients: boolean;
  amILevel3: boolean;

  constructor(apiRoute) {
    this.apiRoute = apiRoute;
    this.loggedIn = false;
    this.username = null;
    this.amIAdmin = false;
    this.canIAddPatients = false;
    this.amILevel3 = false;
    console.log("Initialized with route", apiRoute);
  }

  //   general functions

  async rawRequest(
    endpoint,
    method,
    headers,
    body = null,
    stringifyBody = true
  ) {
    const url = `${this.apiRoute}/${endpoint}`;
    let finalBody;

    if (body) {
      if (stringifyBody) {
        finalBody = JSON.stringify(body);
      } else {
        finalBody = body;
      }
    } else {
      finalBody = null;
    }
    const options: RequestInit = {
      method: method,
      headers: headers,
      credentials: "include",
      body: finalBody,
    };
    try {
      const response = await fetch(url, options);
      return response;
    } catch (error) {
      console.error("API error:", error);
      return null;
    }
  }

  async request(endpoint, method, headers, body = null, stringifyBody = true) {
    const response = await this.rawRequest(
      endpoint,
      method,
      headers,
      body,
      stringifyBody
    );
    if (!response.ok) {
      const errorMessage = `Calling ${endpoint} returned status: ${response.status}`;
      const errorDetails = await response.text();
      const formattedMessage = `API error\n ${errorMessage}\nDetails: ${errorDetails}`;
      console.error(formattedMessage);
      alert(formattedMessage);
      return null;
    }
    const data = await response.json();
    return data;
  }

  async get(endpoint) {
    return await this.request(endpoint, "GET", {}, null);
  }

  async post(endpoint, body) {
    const headers = await this.postPutHeaders();
    return await this.request(endpoint, "POST", headers, body);
  }

  async put(endpoint, body?) {
    const headers = await this.postPutHeaders();
    return await this.request(endpoint, "PUT", headers, body);
  }

  // headers
  async getCSRFToken(token) {
    let csrfToken = null;
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const [name, value] = cookie.trim().split("=");
      if (name === token) {
        csrfToken = decodeURIComponent(value);
      }
    });
    return csrfToken !== null ? csrfToken : null;
  }

  async postPutHeaders() {
    const csrfToken = await this.getCSRFToken("csrf_access_token");
    return {
      "Content-Type": "application/json",
      "X-CSRF-TOKEN": csrfToken,
    };
  }

  async refreshHeaders() {
    const csrfToken = await this.getCSRFToken("csrf_refresh_token");
    return { "X-CSRF-TOKEN": csrfToken };
  }

  // auth flow

  async setNotLoggedIn() {
    this.loggedIn = false;
    if (window.location.pathname !== "/login.html") {
      window.location.href = "/login.html";
    }
  }

  async setLoggedIn(data) {
    this.loggedIn = true;
    this.username = data.username;
    this.amIAdmin = "admin" === data.role;
    this.amILevel3 = rolesLevel3.includes(data.role);
    this.canIAddPatients = rolesCanAddPatients.includes(data.role);
  }

  async login(username, password) {
    const data = await this.request(
      "auth/login",
      "POST",
      { "Content-Type": "application/json" },
      {
        username: username,
        password: password,
      }
    );
    if (data) {
      console.log("Logged in:", data);
      await this.setLoggedIn(data);
      window.location.href = "/";
    }
  }

  async refresh() {
    const headers = await this.refreshHeaders();
    return await this.rawRequest("auth/refresh", "GET", headers, null);
  }

  async logout() {
    await this.get("auth/logout");
    console.log("Logged out");
    await this.setNotLoggedIn();
  }

  async checkLoggedIn(tryRefresh = true) {
    let me = await this.rawRequest("users/me", "GET", {});
    if (me.status === 200) {
      await this.setLoggedIn(await me.json());
    } else if (me.status === 401 && tryRefresh) {
      let refresh_data = await this.refresh();
      if (refresh_data.status === 200) {
        let me = await this.rawRequest("users/me", "GET", {});
        if (me.status === 200) {
          await this.setLoggedIn(await me.json());
          console.log("Token refreshed");
        } else {
          await this.setNotLoggedIn();
        }
      } else {
        await this.setNotLoggedIn();
      }
    }
  }

  async getMe() {
    return await this.get("users/me");
  }

  // API operations

  async getPatients(
    loadType = "basic",
    pageSize = 10,
    page = 1,
    sortBy = ["created_at"]
  ) {
    const queryParams = new URLSearchParams();
    queryParams.append("load_type", loadType);
    queryParams.append("paginate", "true");
    queryParams.append("page_size", String(pageSize));
    queryParams.append("page", String(page));
    sortBy.forEach((value) => queryParams.append("sort_by", value));
    return await this.get(`patients?${queryParams.toString()}`);
  }

  async getLimiters() {
    return await this.get("limiters");
  }

  async getMonitoringTeams() {
    return await this.get("monitoring-teams");
  }

  async revokePaperConsent(patId) {
    await this.put(`patients/${patId}/revoke-consent-paper`);
    window.location.reload();
  }

  async revokeTeleConsent(patId) {
    await this.put(`patients/${patId}/revoke-consent-telemonitoring`);
    window.location.reload();
  }

  async approvePaperConsent(patId) {
    await this.put(`patients/${patId}/approve-consent-paper`);
    window.location.reload();
  }

  async approveTeleConsent(patId) {
    await this.put(`patients/${patId}/approve-consent-telemonitoring`);
    window.location.reload();
  }
}
